import { useTranslation } from 'react-i18next'
import { Link, NavLink, useHistory } from 'react-router-dom'
import {
  Dropdown,
  DropdownTrigger,
  DropdownContent,
  DropdownItem,
} from '@/components/Dropdown'
import { useAuth } from '@/state/auth'
import Avatar from './Avatar'
import FeedbackModal from './FeedbackModal'
import SuggestModal from './SuggestModal'
import '@/styles/TopBar.css'

function TopBar() {
  const { user, isAdmin, signOut } = useAuth()
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <header className="CodeDoor__TopBar">
      <div className="left">
        <Link to="/">
          <img src="/cddr-logo.png" alt="CodeDoor" />
          CodeDoor
        </Link>
      </div>

      <div className="center">
        {
          isAdmin
            ? <>
              <NavLink to="/students" className="label tour4" activeClassName="is-active">{t('vocab.students')}</NavLink>
              <NavLink to="/projects" className="label" activeClassName="is-active">{t('vocab.projects')}</NavLink>
              <NavLink to="/skills" className="label" activeClassName="is-active">{t('vocab.skills')}</NavLink>
            </>
            : <>
              <NavLink to="/" className="label">{t('vocab.ai')}</NavLink>
              <NavLink to="/projects" className="label" activeClassName="is-active">{t('vocab.projects')}</NavLink>
              <NavLink to="/skills" className="label" activeClassName="is-active">{t('vocab.skills')}</NavLink>
              <NavLink to="/peers" className="label" activeClassName="is-active">{t('vocab.peers')}</NavLink>
              <NavLink to="/belture" className="label" activeClassName="is-active">{t('vocab.belture')}</NavLink>
            </>
        }
      </div>

      <div className="right">
        <SuggestModal />
        <FeedbackModal />
        <Dropdown>
          <DropdownTrigger>
            <span className="tour2" >{user.fullName}</span>
            <Avatar user={user} />
          </DropdownTrigger>

          <DropdownContent className="CodeDoor__Dropdown">
            <DropdownItem onSelect={() => history.push('/settings/user')} className="CodeDoor__Dropdown">
              <Link to="/settings/user">
                {t('navigation.user_settings')}
              </Link>
            </DropdownItem>
            {
              isAdmin && (
                <DropdownItem onSelect={() => history.push('/settings/organisation')} className="CodeDoor__Dropdown">
                  <Link to="/settings/organisation">
                    {t('navigation.organisation_settings')}
                  </Link>
                </DropdownItem>
              )
            }
            <DropdownItem onSelect={signOut} className="CodeDoor__Dropdown">
              {t('navigation.logout')}
            </DropdownItem>
          </DropdownContent>
        </Dropdown>
      </div>
    </header>
  )
}

export default TopBar
