import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import API from '@/api'
import Button from './Button'
import Circle from './Icons/Circle'
import Email from './Icons/Email'
import '../styles/FeedbackModal.css'

const SuggestModal = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState('')
  const [isWorking, setIsWorking] = useState(false)
  const { t } = useTranslation()
  const canSubmit = modalData.trim() !== ''

  const toggleModal = () => setModalOpen(!modalOpen)
  const sendFeedbackFn = async () => {
    if (isWorking) return false
    setIsWorking(true);
    try {
      await API.post('/user/feedback', { feedback: modalData })
      setModalData('');
      toggleModal()
    } catch (e) {
      //
    }
    setIsWorking(false);
  }

  return (
    <div>
      <Button className="CodeDoor__FeedbackButton" onClick={toggleModal}>Suggest Project <Email /> </Button>
      <Modal
        className="CodeDoor__FeedbackModal"
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        closeTimeoutMS={300}
      >
        <h1 className="title">{t('vocab.send_suggestion')}</h1>
        <div>
          <textarea
            cols="50"
            placeholder="Your message in here..."
            value={modalData} rows="30"
            onChange={({ target }) => setModalData(target.value)} />
        </div>
        <footer>
          <Button disabled={isWorking} variant="secondary" onClick={toggleModal}>
            {t('vocab.close')}
          </Button>
          <Button disabled={!canSubmit} onClick={sendFeedbackFn}>
            {isWorking ? t('vocab.sending') : t('vocab.send')}
            {isWorking && <Circle />}
          </Button>
        </footer>
      </Modal>
    </div>
  )
}

export default SuggestModal
