import { useState } from 'react'
import { Async, useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import 'intro.js/introjs.css'
import { Link } from 'react-router-dom'
import Button from '@/components/Button'
import Card from '@/components/Card'
import CardList from '@/components/CardList'
import FormGroup from '@/components/FormGroup'
import Loading from '@/components/Loading'
import { useAuth } from '@/state/auth'

const requestOptions = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
};

const listUsersTopics = async ({ userId }) =>
  fetch(`https://container.tensorparc.com/api/openai/beltureUserTopics/${userId}`, requestOptions)
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json());

export default function Belture() {

  const auth = useAuth()
  const [userid] = useState(auth.user.id)
  const today = (new Date().setHours(0, 0, 0, 0)).toString()
  const uniquesession = today.substr(0, 8) + userid.toString()
  const [answer, setAnswer] = useState(auth.user.answer)
  const [topic, setTopic] = useState(auth.user.topic)
  const [questionAnswer, setQuestionAnswer] = useState(auth.user.questionAnswer)
  const [openAiQuestionAnswer, setOpenAiQuestionAnswer] = useState(auth.user.openAiQuestionAnswer)
  const [question, setQuestion] = useState(auth.user.question)
  const [chatlog, setChatlog] = useState([])
  const { t } = useTranslation()
  const { isPending } = useAsync({ deferFn: auth.update })
  const [loadingA, setLoadingA] = useState(false);
  const [loadingB, setLoadingB] = useState(false);

  async function getA(answer) {
    try {
      setLoadingA(true);
      setChatlog(arr => [...arr, answer]);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ answer, userid, uniquesession }),
      };
      // const valueA = await fetch(`https://containerapi.test/api/openai/belture`,  requestOptions )
      const valueA = await fetch(`https://container.tensorparc.com/api/openai/belture`, requestOptions)

      const res = await valueA.json();

      let text = await res.question;
      const topicTxt = await res.topic;
      setTopic(topicTxt);

      if (text === '') text = 'sorry, i have no idea.';
      if (topic === '') setTopic('General');
      setChatlog(arr => [...arr, text]);
      setQuestion(text.slice(text.indexOf('question:'), -1))

    } catch (e) {
      // ToDo proper error handling
    } finally {
      setLoadingA(false);
    }
  }

  async function getB(answer, questionAnswer) {
    try {
      setLoadingB(true);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ question, userid, uniquesession, questionAnswer }),
      };
      // const valueB = await fetch(`https://containerapi.test/api/openai/beltureAnswerFeedback`,  requestOptions )
      const valueB = await fetch(`https://container.tensorparc.com/api/openai/beltureAnswerFeedback`, requestOptions)
      let text = await valueB.text();
      if (text === '') text = 'sorry, i have no idea.';
      setOpenAiQuestionAnswer(text);
    } catch (e) {
      // ToDo proper error handling
    } finally {
      setLoadingB(false);
    }
  }

  async function getC(answer) {
    try {
      setLoadingA(true);
      // setChatlog(arr => [...arr, answer]);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ answer, userid, uniquesession }),
      };
      // const valueA = await fetch(`https://containerapi.test/api/openai/belture`,  requestOptions )
      const valueA = await fetch(`https://container.tensorparc.com/api/openai/belture`, requestOptions)

      const res = await valueA.json();

      let text = await res.question;
      const topicTxt = await res.topic;
      setTopic(topicTxt);

      if (text === '') text = 'sorry, i have no idea.';
      if (topic === '') setTopic('General');
      // setChatlog(arr => [...arr, text]);
      setQuestion(text.slice(text.indexOf('question:'), -1))

    } catch (e) {
      // ToDo proper error handling
    } finally {
      setLoadingA(false);
    }
  }

  const submit = event => {
    event.preventDefault()
    getA(answer)
  }

  const submitAnswer = event => {
    event.preventDefault()
    getB(answer, questionAnswer)
  }

  function submitNewQuestion(answer) {
    setQuestionAnswer(null);
    setOpenAiQuestionAnswer(null);
    setQuestion(null);
    getC(answer);
  }

  return <Card className="CodeDoor__UserSettings__Profile is-large">
    <header>
      <h1 className="title"> </h1>
      <div className="actions" />
    </header>

    {!topic && <form onSubmit={submit}>
      <FormGroup
      >
        <textarea
          className="CodeDoor__Input"
          rows="10"
          value={answer}
          onChange={e => setAnswer(e.target.value)}
          placeholder={t('home.students.belture_expl')}
        />
      </FormGroup>

      <div className="actions">
        <Button className="tour-savebutton" type="submit" disabled={isPending}>{
          isPending
            ? 'send'
            : 'send'
        }</Button>
      </div>
    </form>}

    {topic && <div>
      <Card>This session counts to {topic}</Card>
    </div>}

    <div style={{ maxHeight: '16rem', overflowY: 'auto' }}>
      {chatlog.map((chat, index) =>
        <div key={index} className={(index % 2 === 0) ? 'message sent' : 'message received'}
          style={{
            margin: '1em',
            float: (index % 2 === 0) ? 'left' : 'right',
            padding: '1em',
            backgroundColor: (index % 2 === 0) ? '#e5e7eb' : '#9ca3af',
            color: (index % 2 === 0) ? '#374151' : '#fff',
          }} >{chat}</div>
      )}

    </div>

    <div>
      {loadingA && <Loading />}
    </div>

    {question && <div style={{
      backgroundColor: '#e5e7eb',
      padding: '16px 24px 24px 16px',
      border: '1px',
      borderRadius: '8px',
    }}>
      <div><h3>{question}</h3></div>
      <form onSubmit={submitAnswer}>
        <FormGroup>
          <textarea
            className="CodeDoor__Input"
            rows="15"
            value={questionAnswer}
            onChange={e => setQuestionAnswer(e.target.value)}
            placeholder={t('home.students.belture_expl')}
          />
        </FormGroup>

        <div className="actions">
          <Button className="tour-savebutton" type="submit" disabled={isPending}>{
            isPending
              ? 'send answer'
              : 'send answer'
          }</Button>
        </div>
      </form>
    </div>}

    <div>
      {loadingB && <Loading />}
    </div>

    <div style={{ maxHeight: '16rem', overflowY: 'auto' }}>

      {openAiQuestionAnswer && <div className='message received'
        style={{
          margin: '1em',
          float: 'right',
          padding: '1em',
          backgroundColor: '#9ca3af',
          color: '#fff',
        }}>{openAiQuestionAnswer}</div>}

      {openAiQuestionAnswer && <div className='message received'
        style={{
          margin: '1em',
          float: 'right',
          padding: '1em',
          backgroundColor: '#9ca3af',
          color: '#fff',
        }} ><Button variant="primary" onClick={() => {
          submitNewQuestion(questionAnswer)
        }}>New question!</Button> <Button variant="secondary"><Link to='/'>End Session.</Link></Button></div>}

    </div>

    {topic && <div><h2 className='title is-secondary'>Session Topic:</h2><CardList><Card>{topic}</Card></CardList></div>}

    <Async promiseFn={listUsersTopics} userId={userid}>
      {({ data, error, isLoading }) => {
        if (isLoading) return <Loading />;
        if (error) return `Something went wrong: ${error.message}`;
        if (data) {
          return (
            <div>
              <h2 className='title is-secondary'>Your recent Topics</h2>
              <CardList>
                {data.sort((a, b) => a.topic.localeCompare(b.topic)).map(t => (
                  <Card>
                    <main>
                      <h1>{t.topic}</h1>
                    </main>
                    <footer>

                      <div className="skills">
                        {t.score >= 1 && <div style={{
                          display: 'inline-flex',
                          padding: '4px 8px',
                          backgroundColor: '#fff',
                          border: '1px solid rgb(229, 231, 235)',
                          borderRadius: '4px',
                          whiteSpace: 'break-spaces',
                          color: '#000',
                          height: '24px',
                        }}>
                          {t.score === 1 ? <span>1 Bel </span> : <span>{t.score} Bels </span>}

                          <img src="https://i.imgur.com/Woz22t2.png?1" alt="bel logo" style={{
                            marginLeft: '3px',
                          }} /></div>}
                      </div>
                    </footer>
                  </Card>
                ))}
              </CardList>
            </div>
          );
        }
      }}
    </Async>

  </Card>

}
