// import { useTranslation } from 'react-i18next'
import Belture from '@/components/Belture'
import { Title } from '@/components/Meta'

export default function Skills () {
  // const { t } = useTranslation()

  return (
    <div className="container">
      <Title text='welcome to belture learning experience' />
      <h1 className="title">welcome to belture learning experience</h1>
      <h2 className="title is-secondary">What is your interest today?</h2>
      <Belture />
    </div>
  )
}
